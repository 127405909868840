import React from "react";
import wisuda1 from "./gallery/wisuda/photo1.webp";
import wisuda2 from "./gallery/wisuda/photo2.webp";
import wisuda3 from "./gallery/wisuda/photo3.webp";
import event1 from "./gallery/event/photo1.webp";
import event2 from "./gallery/event/photo2.webp";
import event3 from "./gallery/event/photo3.webp";
import produk1 from "./gallery/produk/photo1.webp";
import produk2 from "./gallery/produk/photo2.webp";
import produk3 from "./gallery/produk/photo3.webp";
import wedding1 from "./gallery/wedding/photo1.webp";
import wedding2 from "./gallery/wedding/photo2.webp";
import wedding3 from "./gallery/wedding/photo3.webp";

const Massonary = () => {
  return (
    <>
      <div className="p-3 md:p-10">
        <h1 className="text-white text-center font-bold text-subTitle mb-5">Gallery Photo</h1>

        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wisuda1} alt="wisuda" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wisuda2} alt="wisuda" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wisuda3} alt="wisuda" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={event1} alt="event" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={event2} alt="event" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={event3} alt="event" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={produk1} alt="produk" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={produk2} alt="produk" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={produk3} alt="produk" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wedding1} alt="Wedding" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wedding2} alt="Wedding" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={wedding3} alt="Wedding" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Massonary;
