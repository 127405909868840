import React, { useState, useRef, useEffect } from "react";
import { FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-scroll";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <>
      <div ref={sidebarRef} className={`z-[110] fixed inset-y-0 right-0 w-[60%] h-screen bg-black bg-opacity-70 transform transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"}`}>
        <ul className="font-medium flex flex-col p-5 mt-4 text-xl mx-auto gap-7">
          <Link to="Home" smooth={true} duration={500} className="block py-2 px-3 text-white rounded cursor-pointer">
            Home
          </Link>
          <li>
            <Link to="Service" smooth={true} duration={500} className="block py-2 px-3 text-white rounded cursor-pointer">
              Service
            </Link>
          </li>
          <li>
            <Link to="Gallery" smooth={true} duration={500} className="block py-2 px-3 text-white rounded cursor-pointer">
              Gallery
            </Link>
          </li>
          <li>
            <Link to="About" smooth={true} duration={500} className="block py-2 px-3 text-white rounded cursor-pointer">
              About
            </Link>
          </li>

          <li>
            <a href="https://www.instagram.com/ekobdsnt" className="block py-2 px-3 text-white rounded" aria-current="page">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@ekobudisnt" className="block py-2 px-3 text-white rounded" aria-current="page">
              <FaTiktok />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/6285810315501?text=Halo%20Kak%2C%0A%0ASaya%20ingin%20tanya-tanya%20sedikit%20nih%20tentang%20jasa%20fotografer%20dari%20Nawasena.%20Apa%20bisa%20ya%2C%20Kak%3F%0A%0ATerima%20kasih%20sebelumnya%21%20%F0%9F%99%8F%F0%9F%93%B8"
              className="block py-2 px-3 text-white rounded"
              aria-current="page"
            >
              <FaWhatsapp />
            </a>
          </li>
        </ul>
      </div>
      <nav className="fixed md:static w-full bg-black z-[100] bg-opacity-70 md:bg-opacity-50 border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <h1 className="flex items-center space-x-3 rtl:space-x-reverse text-white text-xl font-bold">Nawasena</h1>
          <button
            onClick={() => setIsOpen(!isOpen)}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-400 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className="hidden w-[80%] md:block md:w-auto mr-20" id="navbar-default">
            <div className="w-full gap-10 flex flex-row justify-end text-white mb-2">
              <a href="https://www.instagram.com/ekobdsnt" className="block px-3 text-white rounded" aria-current="page">
                <FaInstagram />
              </a>
              <a href="https://www.tiktok.com/@ekobudisnt" className="block px-3 text-white rounded" aria-current="page">
                <FaTiktok />
              </a>
              <a
                href="https://wa.me/6285810315501?text=Halo%20Kak%2C%0A%0ASaya%20ingin%20tanya-tanya%20sedikit%20nih%20tentang%20jasa%20fotografer%20dari%20Nawasena.%20Apa%20bisa%20ya%2C%20Kak%3F%0A%0ATerima%20kasih%20sebelumnya%21%20%F0%9F%99%8F%F0%9F%93%B8"
                className="block px-3 text-white rounded"
                aria-current="page"
              >
                <FaWhatsapp />
              </a>
            </div>
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 text-sm">
              <li>
                <Link to="Home" smooth={true} duration={500} className="block py-2 px-3 text-white rounded md:bg-transparent cursor-pointer">
                  Home
                </Link>
              </li>
              <li>
                <Link to="Service" smooth={true} duration={500} className="block py-2 px-3 text-white rounded md:bg-transparent cursor-pointer">
                  Service
                </Link>
              </li>
              <li>
                <Link to="Gallery" smooth={true} duration={500} className="block py-2 px-3 text-white rounded md:bg-transparent cursor-pointer">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="About" smooth={true} duration={500} className="block py-2 px-3 text-white rounded md:bg-transparent cursor-pointer">
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
