import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import photo1 from "./category/category1.webp";
import photo2 from "./category/category2.webp";
import photo3 from "./category/category3.webp";
import photo4 from "./category/category4.webp";

const CategoryCard = ({ url, title }) => {
  return (
    <div className="relative flex-none w-[300px] h-[200px] min-w-[350px]rounded-md overflow-hidden group bg-cover bg-center" style={{ backgroundImage: `url('${url}')` }}>
      <div className="absolute inset-0 bg-black opacity-25 group-hover:opacity-50 transition-opacity"></div>
      <div className="absolute inset-0 flex items-center justify-center">
        <h1 className="text-white text-2xl font-bold">{title}</h1>
      </div>
    </div>
  );
};

const Carousel = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  return (
    <>
      <h1 className="text-white text-center font-bold py-2  text-subTitle">Category</h1>
      <p className="w-[80%] mx-auto text-white text-center text-sm">Kami Melayani jasa photo dengan kategori sebagai berikut</p>
      <div className="md:w-[80%] mx-auto h-fit">
        <div className="overflow-hidden" ref={emblaRef}>
          <div className="flex space-x-7 md:p-10">
            <CategoryCard url={photo1} title="Wisuda" />
            <CategoryCard url={photo2} title="Produk" />
            <CategoryCard url={photo3} title="Event" />
            <CategoryCard url={photo4} title="Wedding" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
