import React from "react";

const cardService = ({ url, title, subtitle }) => {
  return (
    <div className="w-full text-sm h-fit mx-auto flex justify-center flex-col p-5">
      <img className="w-[100px] mx-auto" src={`${url}`} alt="logo service" />
      <div className="text-center">
        <h1 className="text-2xl p-2 font-bold  md:text-lg">{title}</h1>
        <p className="w-[80%] mx-auto">{subtitle}</p>
      </div>
    </div>
  );
};

export default cardService;
