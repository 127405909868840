import "./App.css";
import CardService from "./Components/cardService";
import Carousel from "./Components/Carousel";
import Massonary from "./Components/Massonary";
import Navbar from "./Components/Navbar";
import { TypeAnimation } from "react-type-animation";
import { descService, descTitleHero } from "./Components/text";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <title>Nawasena - Photografi</title>
      </Helmet>
      {/*================== Hero section ================= */}
      <section id="Home" className="bg-cover bg-center w-full h-screen relative" style={{ backgroundImage: `url(./Hero_Section.webp)` }}>
        {/* ==================== Navbar ==================  */}
        <Navbar />
        <div className="absolute bottom-10 text-white w-full p-3 ">
          {/* Title hero */}
          {/* <h1 className=" text-2xl font-bold mb-3 md:w-[45%] text-title p-2">{titleHero()}</h1> */}
          <TypeAnimation
            sequence={["Abadikan moment spesial dengan fotografer profesional", 2000, "", 1000, "Tangkap setiap kenangan berharga dengan hasil terbaik", 2000, "", 1000, "Ciptakan cerita visual yang tak terlupakan", 2000, "", 1000]}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            className="text-2xl font-bold mb-3 w-[80%] md:w-[45%] text-title"
          />

          {/* Description Hero */}
          <p className="mb-5 w-[85%] md:w-[45%] mt-5">{descTitleHero()}</p>
          {/* button contact Us */}
          <button type="button" className=" text-white hover:text-white border border-white hover:bg-black focus:ring-5 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            <a
              href="https://wa.me/6285810315501?text=Halo%20Kak%2C%0A%0ASaya%20ingin%20tanya-tanya%20sedikit%20nih%20tentang%20jasa%20fotografer%20dari%20Nawasena.%20Apa%20bisa%20ya%2C%20Kak%3F%0A%0ATerima%20kasih%20sebelumnya%21%20%F0%9F%99%8F%F0%9F%93%B8"
              className="block px-3 text-white rounded"
              aria-current="page"
            >
              Contact Us
            </a>
          </button>
        </div>
      </section>
      {/* ================= End Hero Section ============== */}

      {/*  =================Service Section =============== */}
      <section id="Service" className="text-white p-5">
        {/* title */}
        <div className="text-center">
          <h1 className="text-subTitle font-bold text-colorFont w-[60%] mx-auto leading-7">Bagaimana Kami Bekerja</h1>
          <p className=" text-sm mx-auto whitespace-normal px-2 py-3 text-md text-balance md:w-[80%] mb-10">{descService()}</p>
        </div>
        {/* container card service */}
        <div className=" flex flex-col md:flex-row md:w-[80%] mx-auto ">
          <CardService url={"./logo_service1.png"} title={"HUBUNGI KAMI"} subtitle={"Silahkan hubungi kami melalui kontak yang tersedia"} />
          <CardService url={"./logo_service2.png"} title={"Sesi Photo"} subtitle={"Sesi Photo sesuai kesepakatan yang sudah dibuat"} />
          <CardService url={"./logo_service3.png"} title={"penyerahan photo & pembayaran"} subtitle={"Penyerahan photo yang sudah di edit dan melakukan pembayaran"} />
        </div>
      </section>
      {/* ================== end service Section =============== */}

      {/* ================== Client Section =============== */}
      <section className="w-full h-[280px] md:h-[380px] bg-cover bg-center text-white flex flex-col justify-center text-center gap-8 md:gap-20" style={{ backgroundImage: `url(./client_bg.webp)` }}>
        <div className="text-3xl font-bold mt-10">
          <p>50+</p>
          <p className="text-xl">Client</p>
        </div>

        <div className="text-3xl font-bold">
          <p>5000+</p>
          <p className="text-xl">Photos</p>
        </div>
      </section>
      {/* ==================  End Client Section =============== */}
      {/* ================== Category Section =============== */}
      <section className="mt-2">
        <Carousel />
      </section>
      {/* ================== end Category Section =============== */}

      {/* ================== Galley Section =============== */}
      <section id="Gallery" className="mt-2">
        <Massonary />
      </section>

      {/* ================== About Section =============== */}
      <div id="About" className="mt-5 p-3 md:p-10">
        <h1 className="text-subTitle font-bold text-colorFont w-[60%] mx-auto leading-7 text-center mb-3">About Me</h1>
        <div className="flex flex-col md:flex-row p-3 md:p-10 ">
          {/* photo profile */}
          <div style={{ backgroundImage: `url(./pakEko.png)` }} className="  w-[75%] md:w-[45%] bg-no-repeat h-[250px] md:h-[300px] mx-auto bg-contain bg-center"></div>
          <div className="w-[300px] md:w-[60%] mx-auto">
            <p className="text-white py-3 text-balance">
              <span className="font-bold ">Ekobdsnt </span>memulai karirnya dalam dunia fotografi sebagai hobi, yang kemudian berkembang menjadi profesi. Ekobdsnt memiliki pemahaman yang mendalam tentang industri kreatif dan fotografi.
            </p>
            <ul className="text-white w-[80%]">
              <li className="p-2">
                {" "}
                <span className="font-bold">Nama : </span>Ekobdsnt{" "}
              </li>
              <li className="p-2">
                {" "}
                <span className="font-bold">Lokasi : </span> Yogyakarta, Indonesia{" "}
              </li>
              <li className="p-2">
                {" "}
                <span className="font-bold text-balance">Category : </span>Fotografi pernikahan, pre-wedding, potret keluarga, acara perusahaan, fotografi produk, dan Wisuda.{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
